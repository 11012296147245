const downloadFile = (url) => {
  let link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(link.href); // 釋放URL 物件
  document.body.removeChild(link);
};

const downloadCSV = (csvContent, suffix) => {
  // 下載的檔案名稱
  let fileName = new Date().getTime() + suffix;
  // 建立一個 a，並點擊它
  let link = document.createElement('a');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent));
  link.setAttribute('download', fileName);
  link.click();
};

export { downloadFile, downloadCSV };
